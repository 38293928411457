<template>
  <div class="new-css">
    <!-- <Header :headerColor="headerColor" /> -->
    <el-row>
      <el-col :span="12">
        <div class="left-top">
          <p>等级</p>
          <div class="one">
            <img src="../../assets/evaluation/img1.png" />
            <span>
              企业应该开始对实施智能制造的基础和条件进行规划，能够对核心业务（设计、生成、物流、销售、服务）
              <strong>进行流程化管理</strong>
            </span>
          </div>
          <div class="one">
            <img src="../../assets/evaluation/img2.png" />
            <span>
              企业应采用
              <strong>自动化技术、信息技术手段</strong
              >对核心装备和业务等进行改造和规范，实现单一业务的
              <strong>数据共享</strong>
            </span>
          </div>
          <div class="one">
            <img src="../../assets/evaluation/img3.png" />
            <span>
              企业应对装备、系统等开展
              <strong>集成</strong>，实现跨业务间的数据共享
            </span>
          </div>
          <div class="one">
            <img src="../../assets/evaluation/img4.png" />
            <span>
              企业应对人员，资源制造等进行数据挖掘，形成
              <strong>知识，模型</strong>等，实现对核心业务的精准预测和优化
            </span>
          </div>
          <div class="one">
            <img src="../../assets/evaluation/img5.png" />
            <span>
              企业应基于模型持续驱动业务优化和创新，实现产业链协同并衍生新的
              <strong>制造模式和商业模式</strong>
            </span>
          </div>
        </div>
        <div class="left-bottom">
          <p>领导整体监控（成本、速度、质量）</p>
          <img src="../../assets/evaluation/img.png" />
        </div>
      </el-col>
      <el-col :span="12">
        <div class="right">
          <div class="top">
            <div>
              <img src="../../assets/evaluation/img6.png" />
            </div>
            <div class="title">
              <p>新建自评自测</p>
              <p>请企业根据自身业务活动选择制造要素下需要裁减的能力域</p>
              <p>( 注意：请在不涉及的能力域前打√，打√为不答项，请勿选错。)</p>
            </div>
          </div>
          <div class="check-box" v-for="(item, index) in list" :key="index">
            <el-checkbox
              v-model="item.checkAll"
              @change="handleAllChange(index, item.checkAll)"
              >{{ item.name }}</el-checkbox
            >
            <div style="margin: 15px 0"></div>
            <el-checkbox-group
              v-model="item2.check"
              v-for="(item2, index2) in item.data"
              :key="index2"
              @change="handleItemChange(index, index2, item2.check)"
            >
              <el-checkbox>{{ item2.name }}</el-checkbox>
            </el-checkbox-group>
          </div>
          <el-button type="primary" @click="submitInfo">开始答题</el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
// import Header from "../../components/Header";
import { post } from "../../api/http";

export default {
  components: {
    // Header,
  },
  data() {
    return {
      headerColor: "#5A8BFF",
      options: [],
      list: [
        {
          name: "设计",
          checkAll: false,
          data: [
            { name: "产品设计", check: false },
            { name: "工艺设计", check: false },
          ],
        },
        {
          name: "物流",
          checkAll: false,
          data: [{ name: "物流", check: false }],
        },
        {
          name: "销售",
          checkAll: false,
          data: [{ name: "销售", check: false }],
        },
        {
          name: "服务",
          checkAll: false,
          data: [
            { name: "客服服务", check: false },
            { name: "产品服务", check: false },
          ],
        },
      ],
      value: "",
      checkList: [],
      stopExecution: false,
    };
  },
  mounted() {},
  methods: {
    handleAllChange(index, checkAll) {
      this.list[index].data.map((item) => {
        if (checkAll) {
          item.check = true;
        } else {
          item.check = false;
        }
      });
    },
    handleItemChange(index, index2, check) {
      if (check) {
        this.list[index].checkAll = true;
        this.list[index].data.forEach((item) => {
          if (!item.check) {
            this.list[index].checkAll = false;
          }
        });
      } else {
        this.list[index].data[index2].check == false;
        this.list[index].checkAll = false;
      }
    },
    // 保存
    submitInfo() {
      if (this.stopExecution) {
        this.$message({
          message: "请求中,请稍等...",
          type: "warning",
        });
        return;
      }
      this.stopExecution = true;
      this.list.map((item) => {
        item.data.map((childItem) => {
          if (childItem.check) {
            this.checkList.push({
              Sort: item.name,
              SubdomainName: childItem.name,
            });
          }
        });
      });
      post("/CutTitle?CompName=" + JSON.parse(localStorage.getItem("userNo")), {
        data: JSON.stringify(this.checkList),
      })
        .then((resp) => {
          this.stopExecution = false;
          if (resp.data.code == 200) {
            this.$router.push("./answer");
          } else {
            this.$message.error(resp.data.message);
          }
        })
        .catch(() => {
          this.stopExecution = false;
          this.$message.error("系统异常，请稍后再试");
        });
    },
  },
};
</script>

<style>
/* 左边 */
.new-css .left-top .one {
  margin: 10px auto;
  display: flex;
  align-items: center;
}
.new-css .left-top > p,
.new-css .left-bottom > p {
  margin: 30px auto 15px auto;
  width: 81%;
  border-left: 4px solid #5a8bff;
  padding-left: 5px;
}
.new-css .one {
  background-color: white;
  padding: 5px 10px;
  width: 80%;
}
.new-css .left-top .one img {
  width: 65px;
}
.new-css .left-top .one span {
  padding-left: 20px;
}
.new-css strong {
  color: red;
}
.new-css .left-bottom > img {
  width: 82%;
  margin: 0 auto;
  display: flex;
}
/* 右边 */
.new-css .right {
  background-color: white;
  width: 80%;
  margin-top: 20px;
  text-align: center;
  box-shadow: 0px 0px 10px -2px #b8b8b8;
}
.new-css .right .top {
  display: flex;
  vertical-align: top;
  padding: 20px 30px 5px 30px;
  border-bottom: 1px solid silver;
  /* 没有上边框、左右边框 */
  border-style: none none dashed;
  text-align: start;
}
.new-css .right .top img {
  width: 35px;
}
.new-css .right .title {
  margin-left: 5px;
}
.new-css .right .title p:nth-of-type(1) {
  font-size: 20px;
  font-weight: bold;
  margin-top: 4px;
}

.new-css .right .title p:nth-of-type(3) {
  color: red;
  font-size: 10px !important;
}
.new-css .check-box {
  padding: 20px 30px 5px 65px;
  text-align: start;
}
.new-css .el-checkbox,
.new-css .el-checkbox__input {
  display: inline !important;
}
.new-css label span {
  color: black;
}
.new-css .el-checkbox-group {
  display: inline-block;
  margin-left: 30px;
}
.new-css .el-checkbox-group .el-checkbox__label {
  font-size: 13px !important;
  color: rgb(83, 83, 83) !important;
}
.new-css .right .el-button {
  margin: 20px 0;
  padding: 10px 20px !important;
}
</style>